export const toggleMenuIcon = (button) => {
  for (let item of button.querySelectorAll(".icon")) {
    item.classList.toggle("not-shown");
  }
  ;
  return button.querySelector(".icon:not(.not-shown)").classList.add("shown");
};
export const addHeadingAnchors = () => {
  if (document.body.classList.contains("docs")) {
    for (let heading of document.querySelectorAll(".content h2[id], .content h3[id]")) {
      let anchorLink = document.createElement("a");
      anchorLink.innerText = "#";
      anchorLink.href = `#${heading.id}`;
      anchorLink.classList.add(".heading-anchor".slice(1));
      heading.appendChild(anchorLink);
    }
  }
};
